import axios from '@jslib/axios'

export const endorsementQuotationMotor = data =>{
  return axios({
    method: 'post',
    url: '/admin/endorsement/motors',
    data
  })
}
export const endorsementQuotationConstractor = data =>{
  return axios({
    method: 'post',
    url: '/admin/endorsement/risks',
    data
  })
}
export const endorsementQuotationTravel = data =>{
  return axios({
    method: 'post',
    url: '/admin/endorsement/travel',
    data
  })
}
export const endorsementQuotationHome = data =>{
  return axios({
    method: 'post',
    url: '/admin/endorsement/home',
    data
  })
}
export const endorsementQuotationEmployee = data =>{
  return axios({
    method: 'post',
    url: '/admin/endorsement/employee/compensation',
    data
  })
}
export const endorsementQuotationBusiness = data =>{
  return axios({
    method: 'post',
    url: '/admin/endorsement/business/package',
    data
  })
}
export const endorsementQuotationDomestic = data =>{
  return axios({
    method: 'post',
    url: '/admin/endorsement/domestic/helper',
    data
  })
}
export const endorsementQuotationConstruction = data =>{
  return axios({
    method: 'post',
    url: '/admin/endorsement/construction/employee/compensation',
    data
  })
}
export const endorsementQuotationOthers = data =>{
  return axios({
    method: 'post',
    url: '/admin/endorsement/others',
    data
  })
}
export const computedEndorsement = data=>{
  return axios({
    method: 'post',
    url: '/admin/endorsement/quotation',
    data
  })
}