<template>
  <section class="dirver-form">
    <div class="flex">
      <v-title title="Insured Detail"></v-title>
      <div>
        <v-button
          class="button-theme"
          @click="handlerAddDriver">
          Add Traveler
        </v-button>
      </div>
    </div>

    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
    >
      <el-row
        v-for="(item,index) in formData.insured_detail"
        :key="index"
        class="row-bg"
        :gutter="20"
        justify="space-around"
      >
        <div class="flex">
          <span>Traveler {{ index + 1 }}</span>
          <span
            class="hand"
            @click="handlerDeleteDriver(index)">Delete</span>
        </div>
        <v-row-col>
          <div class="form-item ">
            <span>Relationship with Proposer</span>
            <el-form-item prop="relationship_id">
              <v-select
                v-model="item.relationship_id"
                :list="relationshipOptions"
                :disabled="isFirst&&index==0"
              />
            </el-form-item>
          </div>
        </v-row-col>
        <v-row-col>
          <div class="form-item ">
            <span>Date of Birth</span>
            <el-form-item prop="birth">
              <v-date-picker
                v-model="item.birth_date"
                :hasDeault="true"
                :disabled="isFirst&&index==0"
              ></v-date-picker>
            </el-form-item>
          </div>
        </v-row-col>
        <v-row-col>
          <div class="form-item">
            <span>Name</span>
            <el-form-item prop="name">
              <v-input
                v-model="item.name"
                :disabled="isFirst&&index==0"
              />
            </el-form-item>
          </div>
        </v-row-col>
        <v-row-col>
          <div class="form-item">
            <!--                  <span>HKID/Passport Number</span>-->
            <el-form-item
              :ref="'insured_detail.' + index + '.id_type'"
              :prop="'insured_detail.' + index + '.id_type'"
              class="form-none-margin-bottom">
              <v-radio-group
                v-model="item.id_type"
                :disabled="isFirst&&index==0"
                :list="personalIdTypOptions"/>
            </el-form-item>
            <el-form-item
              v-if="item.id_type==1"
              :ref="'insured_detail.' + index + '.id_no'"
              :prop="'insured_detail.' + index + '.id_no'"
              :rules="rules.id_no"
            >
              <v-input
                v-model="item.id_no"
                :disabled="isFirst&&index==0"
                :isUpper="true"
                class="mg-t-5"/>
            </el-form-item>
            <el-form-item
              v-if="item.id_type==2"
              :ref="'insured_detail.' + index + '.pp_no'"
              :prop="'insured_detail.' + index + '.pp_no'"
            >
              <v-input
                v-model="item.id_no"
                :disabled="isFirst&&index==0"
                class="mg-t-5"/>
            </el-form-item>
          </div>
        </v-row-col>

      </el-row>
    </el-form>
  </section>
</template>

<script>
import options from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import IDCheck from '@/utils/idcheck'
import dayjs from 'dayjs'
import {mapState,mapActions} from 'vuex'
export default {
  name: 'travelerDetailForm',
  mixins: [options,MixinRules],
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    formDataLock: {
      type: Number,
      default: 0
    },
    carType: {
      type: Number,
      default: 0
    },
    policyOneDriver:{
      type: Boolean,
      default: false
    }
  },
  data() {
    const checkIdNo = (rule, value, callback) => {
      const index = rule.field.split('.')[1]
      const { id_no,id_type } = this.formData.insured_detail[index]
      if( value && !IDCheck(value)) {
        callback(new Error('請輸入正確的身份證號碼'))
      }
      // else {
      //   if(!id_no && !value) {
      //     callback(new Error('請輸入正確的身份證號碼'))
      //   }
      // }
      callback()
    }
    const checkPPNo = (rule, value, callback) => {
      const { pp_no,policy_holder_id_type } = this.formData
      if(policy_holder_id_type===2&& !pp_no && !value) {
        callback(new Error('請輸入Passport'))
      }
      callback()
    }

    return {
      oldFormDataLock:-1,
      id_type: '',
      policy_holder_is_traveler:0,
      driver: {
        name: '',
        birth_date: '',
        id_type: 1,//1-hkid 2-passport,
        id_no: '',
        relationship_id: '',//1 Parent /2 Child /3 Spouse / 4 Relative /5 Friend /6 Employee /7 Employer
      },
      formData: {
        insured_detail: [{
          ...this.driver
        }]
      },
      rules: {
        id_no: [
          { validator: checkIdNo, trigger: ['blur'] }
        ]
      },
    }
  },
  computed:{
    ...mapState('travel', ['travelerNo']),
    isFirst(){
      return this.policy_holder_is_traveler ==1
    }
  },
  watch:{
    form: {
      deep: true,
      immediate: true,
      handler(val) {
        if(this.formDataLock!==this.oldFormDataLock){
          this.oldFormDataLock = this.formDataLock
          if(!val)
            return
          let {insured_detail=[{...this.driver}]} =val
          if(insured_detail.length>0)
          {
            let {policy_holder_is_traveler} = val
            this.policy_holder_is_traveler = policy_holder_is_traveler
            insured_detail.forEach((item,index)=>{
              if(item.birth_date)
                item.birth_date = dayjs(item.birth_date).format('YYYY-MM-DD')
              else  item.birth_date = ''
              if(index==0&&policy_holder_is_traveler==1)
                item.relationship_id = 8
            })
            this.formData.insured_detail = insured_detail
          }
          this.driver  = JSON.stringify(this.driver)

        }
      }
    },
    travelerNo:{
      immediate:true,
      handler(val){
        let length =  this.formData.insured_detail.length
        if(val){
          if(length<val){
            let add = val-length
            for(var i=0;i<add;i++){
              let driver = JSON.parse(this.driver)
              this.formData.insured_detail.push(driver)
            }
          }else
          {
            if(length>val) {
              let data =   this.formData.insured_detail.slice(0,val)
              this.formData.insured_detail = data
            }
          }
        }
      }
    }
  },
  methods: {
    ...mapActions('travel', ['setTravelNo']),
    submitForm(formName) {
      this.$refs['form'].validate(valid => {
        if(valid) {
          this.$emit('getFormData', formName, this.formData)
        } else {
        }
      })
    },
    //添加司机
    handlerAddDriver() {
      let driver = JSON.parse(this.driver)
      // if( this.formData.insured_detail.length>=4)
      //   return
      this.formData.insured_detail.push(driver)
      let length =  this.formData.insured_detail.length
      this.setTravelNo(length)
    },
    handlerDeleteDriver(index) {
      if(this.isFirst&&index==0)return
      let length =  this.formData.insured_detail.length
      if (length <= 1) return
      this.formData.insured_detail.splice(index, 1)
      this.setTravelNo(this.formData.insured_detail.length)
    },
    // 關聯投保人資料
    handlerPolicyDriverInfo(data) {
      const {
        policy_holder_name,
        policy_holder_id_no, policy_holder_id_type, policy_holder_occ_id,
        policy_holder_birth,policy_holder_is_traveler
      } = data
      this.policy_holder_is_traveler = policy_holder_is_traveler
      if(policy_holder_is_traveler)
      {
        let birth = ''
        if(policy_holder_birth)
          birth = dayjs(policy_holder_birth).format('YYYY-MM-DD')
        Object.assign(this.formData.insured_detail[0], {
          name: policy_holder_name,
          occ_id: policy_holder_occ_id,//i
          birth_date: birth,
          id_type: policy_holder_id_type,//1-hkid 2-passport,
          id_no: policy_holder_id_no,
          relationship_id:8
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dirver-form {
  .row-bg {
    background: #fff;
    border-radius: 8px;
    padding: 10px 0;
    margin-bottom: 10px;
    margin-right: 0!important;
    margin-left: 0!important;
    .flex {
      padding: 10px;

      span {
        color: #000;
        font-size: 16px;
        font-weight: 500;

        &:nth-child(2) {
          font-size: 14px;
          color: #076081;
        }
      }
    }
  }

  .flex {
    justify-content: space-between;
    align-items: center;
  }
}
</style>
