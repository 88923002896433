<template>
  <section class="policy-holder">
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
    >
      <!--      personal-->
      <el-row
        class="row-bg"
        :gutter="10"
        justify="space-around">
        <v-row-col>
          <v-row-col
            :same="true"
          >
            <div class="form-item">
              <span>Name</span>
              <el-form-item prop="name">
                <v-input
                  v-model="formData.name"
                  :disabled="checkDisabled('name')"
                />
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true">
            <div class="form-item ">
              <span>Date of Birth</span>
              <el-form-item prop="birth_date">
                <v-date-picker
                  v-model="formData.birth_date"
                  :hasDeault="true"
                  :disabled="checkDisabled('birth_date')"
                />
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col  :same="true">
            <div

              class="form-item ">
              <el-form-item
                prop="id_type"
                class="form-none-margin-bottom">
                <v-radio-group
                  v-model="formData.id_type"
                  :list="personalIdTypOptions"
                />
              </el-form-item>
              <el-form-item
                v-if="formData.id_type === 1"
                prop="policy_holder_id_no">
                <v-input
                  v-model="formData.id_no"
                  :disabled="checkDisabled('id_no')"
                  class="mg-t-5"
                  :isUpper="true"
                />
              </el-form-item>
              <el-form-item
                v-else
                prop="pp_no"
              >
                <v-input
                  v-model="formData.pp_no"
                  class="mg-t-5"
                />
              </el-form-item>
            </div>
          </v-row-col>
        </v-row-col>

      </el-row>
    </el-form>
  </section>
</template>
<script>
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import IDCheck from '@/utils/idcheck'
import {mapState,mapActions} from 'vuex'
export default {
  name: 'PolicyHolder',
  components: {

    // ConfirmInformation
  },
  mixins: [MixinOptions,MixinRules],
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    formDataLock: {
      type: Number,
      default: 0
    },
    carType:{
      type: Number,
      default: 0
    },
    clientType:{
      type: Number,
      default: 1
    }
  },
  data() {
    const checkIdNo = (rule, value, callback) => {
      const { id_no,id_type } = this.formData
      if( id_type == 1 && !IDCheck(value)) {
        callback(new Error('請輸入正確的身份證號碼'))
      }
      // else {
      //   if(!id_no && !value) {
      //     callback(new Error('請輸入正確的身份證號碼'))
      //   }
      // }
      callback()
    }
    const checkPPNo = (rule, value, callback) => {
      const { pp_no,id_type } = this.formData
      if(id_type===2&& !pp_no && !value) {
        callback(new Error('請輸入Passport'))
      }
      callback()
    }
    return {
      oldFormDataLock: -1,
      formData: {
        /////policyholder
        name:'',
        id_type:'',
        id_no:'',
        pp_no:'',
        birth_date:'',
      },
      extraRules: {
        id_no: [
          { validator: checkIdNo, trigger: [ 'blur'] }
        ],
        pp_no: [
          { validator: checkPPNo, trigger: ['blur'] }
        ],

      }
    }
  },
  computed: {
    ...mapState('quotation', ['productID']),
    type() {
      // return this.formData.cust_typ
      return 1
    },
    localAllOptions() {
      const classId = this.formData.class_id
      if (classId === 14) {
        return this.allOptions
      } else {
        return this[`allOptions${classId}`]
      }
    },
  },
  watch: {
    // 表單鎖，外層會更新，數值變化了才可以更新 formData
    formDataLock: {
      handler(val, old = -1) {
        if (val !== old) {
          this.oldFormDataLock = old
        }
      }
    },
    form: {
      deep: true,
      immediate: true,
      handler(val) {
        if (this.formDataLock !== this.oldFormDataLock) {
          //   // 觸發更新就賦值，避免二次更新
          this.oldFormDataLock = this.formDataLock
          Object.keys(this.formData).some(key => {
            if (key in val) {
              this.formData[key] = val[key]
            }
          })
          // 給 pp_no 賦值
          const {id_type, id_no,pp_no} = val
          if (id_type === 2 ) {
            this.formData.pp_no = id_no||pp_no
          }
        }
        // 用于匹配是否更新了信息
        // this.oldFormData = JSON.parse(JSON.stringify(this.formData))
      }
    },
  },
  methods: {

    checkDisabled(field) {
      const {disable_filed = []} = this.form
      return disable_filed.includes(field)
    },
    getSubFormData(form, data) {
      this.subForm[form] = true
      this.formData = Object.assign(this.formData, data)
    },
    submitForm(formName) {
      this.$refs.form.validate(valid => {
        if (valid) {
          let params = this.formData
          if (params.id_type === 2) {
            params.id_no = params.pp_no
          }
          if(this.clientType==2){
            params.id_type=3
          }
          params ={
            domestic_helper_detail: params
          }
          this.$emit('getFormData', formName, params)
        }
      })
      //
    },
  }
}
</script>
<style lang="scss" scoped>
.policy-holder {
  .edit-holder-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 20px;

    .card {
      padding-bottom: 40px;
    }
  }

  .title {
    display: block;
    padding-bottom: 15px;
    color: #616266;
    font-size: 20px;
    font-weight: 500;
  }

  .form-flex {
    display: flex;
    justify-content: space-between;

    & > .form-block {
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .form-block {
    margin-bottom: 15px;

    & > span:first-child {
      display: block;
      padding-bottom: 5px;
      font-weight: 500;
      font-size: 14px;
    }
  }
  .holder-title{
    margin-bottom: 16px;
    display: block;
  }
}
</style>
